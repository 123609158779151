<template>
  <div class="welcome">
    欢迎使用IPAAS管理平台
  </div>
</template>

<script>
export default {
  name: "Welcome"
}
</script>

<style scoped>
.welcome {
  font-weight: bold;
  font-size: 40px;
  width: 100%;
  min-height: 100vh;
  background-image: url("../assets/wellcome2.jpg");
  background-size:100% 100%;
}
</style>